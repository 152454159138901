<template>
  <div>
      <div class="fb-style marketing-background firstSectionPadFix">
    <div class="fb-grid">
      <div class="fb-content">
        <h1>Marketing Strategy</h1>
        <h3>
          Use your data, and plan the right <br>strategy for your products and services.
        </h3>
        <router-link to="/contact"><a><button class="fb-content-btn">Start strategizing</button></a></router-link>
      </div>
      <img src="@/assets/images/Icon_open-graph.svg" />
    </div>

  </div>

  <div class="label labelColorGrey">
    <div class="hrdiv hrdiv-grey-color"></div>
    <div class="pdiv">
      <p>&nbsp;Marketing Strategy</p>
    </div>
  </div>

  <div class="SecondBannerWrapper labelColorGrey">
    <div class="gridSBW">
      <div class="FirstWrap">
        <div class="wrap">
          <h2>Marketing Strategy</h2>
          <p>
            We ask questions to understand your business goals. By
            understanding your business better, we plan what resources
            are required to move you towards that goal.
          </p>
        </div>
        <div class="wrap">
          <h2>Using Data</h2>
          <p>
            We start with data, and have three areas of compentences,
            Business Intelligence, Website development and Paid Media & Email marketing.
          </p>
        </div>
        <div class="wrap">
          <h2>Business Intelligence</h2>
          <p>
            We build end to end cloud based reporting solutions, which
            supports your daily operations and your marketing efforts. We
            use leading data visualization tools, we build your BI platform
            manage your data. We bring you new insights to your
            business so you can focus on growth. We offer a variety of
            options from developing dashboards to fully outsourced BI teams.
          </p>
        </div>
        <div class="wrap">
          <h2>Data Science and automation</h2>
          <p>
            When data managed correctly and is of high quality, it opens
            up new possibilities for your company. Machine Learning and
            Robotic Process Automation (RPA) will be feasible, allowing
            you to discover new aspects of your company, unlock new
            revenue streams, and automate tedious back office
            processes, saving costs and improving your data quality.
          </p>
        </div>
        <div class="wrap wrap-btn">
          <router-link to="/contact"><button class="blk btn"><a>Build Your Website</a> </button></router-link>
        </div>
      </div>

      <div class="SecondWrap">
        <h2>Our approach</h2>
        <div class="platforms">
            <h4 class="comma">Business Intelligence</h4>
            <h4 style="display: block;" id="category" class="comma">Website development</h4>
            <h4 style="display: block;" id="category">Paid Media & Email marketing</h4>
        </div>
      </div>
    </div>
  </div>

  <MainWhatWeDo/>
  <Testimonials/>
  <WhoWeAre/>
  <div class="trustedby">
  <Trustedby/>
  </div>
  </div>
</template>

<script>
import Testimonials from "../components/Testimonials.vue";
import MainWhatWeDo from "../components/MainWhatWeDo.vue";
import WhoWeAre from "./WhoWeAre.vue";
import Trustedby from "../components/Trustedby.vue";
export default {
  name: "Marketing",
  title: "Marketing Strategy",
  components: { Testimonials, MainWhatWeDo, WhoWeAre, Trustedby },
};
</script>

<style scoped>
@import "../assets/css/web.css";
@import "../assets/css/tablet.css";
@import "../assets/css/mobile.css";
.trustedby {
  background: #f8f8f8;
}
</style>
